<div [ngClass]="(!equipDeleted ? (checkEquipConfigIsSensorOrBuildingOrHeatExchanger ?  data.isEquipConnected ? 'sensorModelHeightwithNote' :  'sensorModel': data.isEquipConnected ? 'equipHeightwithNote': 'equipModel'): 'equipDeleted') ">

    <div *ngIf="data.childData?.equipData?.displayName" class="equipHeaderText notranslate">{{addTooltip(data.childData.equipData.displayName)}} Config</div>

    <div class="row m-t-16" [ngClass]="checkEquipConfigIsSensorOrBuildingOrHeatExchanger ? 'p-l-15': ''">
        <div [ngClass]="checkEquipConfigIsSensorOrBuildingOrHeatExchanger ? 'col-12': 'col-4'" *ngIf="data.childData?.equipData?.displayName">
            <div class="labelText">
                Equip Name
            </div>
            <div matTooltip="{{data.childData.equipData.displayName}}"
            matTooltipPosition='above' class="equipNameForm notranslate">{{data.childData.equipData.displayName}}</div>
        </div>
        <div class="col-4" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
            <div class="labelText">
                Brand
            </div>
            <div class="equipNameForm">{{data.childData.equipData.brand != undefined?data.childData.equipData.brand:"-"}}</div>
        </div>
        <div class="col-4" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
            <div class="labelText">
                Model
            </div>
            <div class="equipNameForm">{{data.childData.equipData.model != undefined?data.childData.equipData.model:"-"}}</div>
        </div>
    </div>
    <div  class="row m-t-10" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
        <div class="col-4">
            <div class="labelText">
                Capacity
            </div>
            <div class="equipNameForm">{{data.childData.equipData.capacity != undefined?data.childData.equipData.capacity:"-"}}</div>
        </div>
        <div *ngIf="data.childData?.equipData?.maxMaxFrequency" class="col-4">
            <div class="labelText">
                Max VFD Frequency
            </div>
            <div class="equipNameForm">{{data.childData.equipData.maxMaxFrequency != undefined?data.childData.equipData.maxMaxFrequency:"-"}}</div>
        </div>
    </div>

    <div *ngIf="showNote" class="row m-t-2 m-b-10 note-text" >
        <div class="col-12">
            Please note: you will not be able to reposition sensor devices on the pipe as parent equip is already connected to other equip/s, please remove all connections to reposition the sensor device        </div>
    </div>

    <div class="row pointsHeader">
        <div class="p-lr-0" [ngClass]="isSensorNode ? 'col-8': 'col-6'">
            <div class="d-flex">
                <div class="w-100 ">Points</div>
                <div class="flex-shrink-1 verticalLine">|</div>
            </div>
        </div>

        <div class="p-lr-0" [ngClass]="isSensorNode ? 'col-4': 'col-2'">
            <div class="d-flex">
                <div class="w-100 ">Master <i  matTooltip="Point Limit: {{pointLimit}}" matTooltipPosition="above"   matTooltipClass="mastertooltip" class="fa-solid fa-circle-info infoIcon"></i></div>
                <div class="flex-shrink-1 verticalLine" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">|</div>
            </div>
        </div>

        <div class="col-2 p-lr-0" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
            <div class="d-flex">
                <div class="w-100 ">Hover</div>
                <div class="flex-shrink-1 verticalLine">|</div>
            </div>
        </div>
        <div class="col-2 p-lr-0" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
            <div>Click</div>
        </div>
    </div>

    <div *ngIf="isLoading" class="ngxLoader">
        <ngx-skeleton-loader [theme]="{height: '20px', 'background-color': '#eff0f1'}" count="10"></ngx-skeleton-loader>
    </div>

    <div *ngIf="!isLoading" cdkDropList class="tableContainer" [ngClass]="{equipDeleted: 'equipDeleted'}" (cdkDropListDropped)="equipPrimaryPoint($event)">
        <ng-container *ngIf="!equipDeleted">
        <div class="row pointRowStyle notranslate"  [ngClass]="{'pointRow':!data.isEquipConnected && isSensorNode}" *ngFor="let point of equipList.parentEquip.points" cdkDrag [cdkDragDisabled]="!isSensorNode || data.isEquipConnected">
            <div class="pointText notranslate" [ngClass]="isSensorNode ? 'col-8': 'col-6'">
                <i *ngIf="!data.isEquipConnected && isSensorNode" [ngClass]="data.isEquipConnected ? 'grey-color': 'primary-color'"   class="fas fa-grip-vertical p-r-5" aria-hidden="true"></i>     {{point.pointName}}
            </div>
            <div class="textCenter p-5-0" [ngClass]="isSensorNode ? 'col-4': 'col-2'">
                <span *ngIf="data.isEquipConnected && isSensorNode && !(data.childData.class.includes('btuMeter'))" class="disableToolTip" matTooltip="remove all the connections to add/remove the sensor device" matTooltipPosition="above" matTooltipClass="disableMastertooltip">
                <mat-checkbox [disabled]="true" [checked]="point.master" [(ngModel)]="point.master"
                    [ngModelOptions]="{standalone: true}"></mat-checkbox>
                </span>

                <span *ngIf="!isSensorNode || (isSensorNode && !data.isEquipConnected) || data.childData.class.includes('btuMeter')" >
                    <mat-checkbox [disabled]="checkMasterDisable(point)" [checked]="point.master" [(ngModel)]="point.master"
                        [ngModelOptions]="{standalone: true}"></mat-checkbox>
                </span>
            </div>
            <div class="col-2 textCenter p-5-0" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
                <mat-checkbox [checked]="point.hover" [(ngModel)]="point.hover"
                    [ngModelOptions]="{standalone: true}"></mat-checkbox>
            </div>
            <div class="col-2 textCenter  p-5-0" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
                <mat-checkbox [disabled]="checkClickDisable(point)" [checked]="point.click" [(ngModel)]="point.click"
                    [ngModelOptions]="{standalone: true}"></mat-checkbox>
            </div>
        </div>
        <br/>

        <div *ngIf="!isHeaderPipe(equipList.parentEquip.class) && !isBypassPipe(equipList.parentEquip.class)">
        <div *ngFor="let subEquipData of equipList.childEquip">
            <div class="row col-10 pointsHeaderText notranslate">
            {{ subEquipData.name }}
            </div>
            <div cdkDropList (cdkDropListDropped)="dropSubEquip($event,subEquipData)">
                <div class="row pointRowStyle"  [ngClass]="{'pointRow':!data.isEquipConnected}" *ngFor="let subEquip of subEquipData.points" cdkDrag [cdkDragDisabled]="data.isEquipConnected">
                    <div class="pointText col-6 notranslate">
                        <i [ngClass]="data.isEquipConnected ? 'grey-color': 'primary-color'"   class="fas fa-grip-vertical p-r-5" aria-hidden="true"></i> {{ subEquip.pointName }}
                    </div>
                    <div class="textCenter p-5-0" [ngClass]="isSensorNode ? 'col-4': 'col-2'">
                        <span *ngIf="data.isEquipConnected" class="disableToolTip" matTooltip="remove all the connections to add/remove the sensor device" matTooltipPosition="above" matTooltipClass="disableMastertooltip">
                        <mat-checkbox [disabled]="checkChildMasterDisable(subEquip)" [checked]="subEquip.master"
                            [(ngModel)]="subEquip.master" [ngModelOptions]="{standalone: true}"></mat-checkbox>
                        </span>
                        <span *ngIf="!data.isEquipConnected">
                            <mat-checkbox [disabled]="checkChildMasterDisable(subEquip)" [checked]="subEquip.master"
                                [(ngModel)]="subEquip.master" [ngModelOptions]="{standalone: true}"></mat-checkbox>
                            </span>
                    </div>
                    <div class="col-2 textCenter p-5-0" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
                        <mat-checkbox [checked]="subEquip.hover" [(ngModel)]="subEquip.hover"
                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                    </div>
                    <div class="col-2 textCenter  p-5-0" *ngIf="!checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
                        <mat-checkbox [disabled]="checkClickDisable(subEquip)" [checked]="subEquip.click" [(ngModel)]="subEquip.click"
                            [ngModelOptions]="{standalone: true}"></mat-checkbox>
                    </div>
                </div>
            </div>
            </div>
        </div>
    
        <div *ngIf="!equipDeleted && !isHeaderPipe(data.childData.class) && !isBypassPipe(equipList.parentEquip.class) && (!checkEquipConfigIsSensorOrBuildingOrHeatExchanger || isSensorNode)" class="row p-t-10">
            <div class="col-6 pointsHeaderText">
                Custom Points
            </div>
            <div class="col-6">
                <i (click)="addPointModel()" class="fa-solid fa-plus addIcon"></i>
            </div>
        </div>
    
        <div class="row pointRowStyle" *ngFor="let custompoint of customPointList">
            <div class="pointText notranslate" [ngClass]="isSensorNode ? 'col-6': 'col-5'">
                {{custompoint.pointName}}
            </div>
            <div class="p-5-0 textAlignRight" [ngClass]="isSensorNode ? 'col-2': 'col-1'">
                <i (click)="deleteCustomPoint(custompoint)" class="fa-solid fa-trash deleteIcon"></i>
            </div>
            <div class="textCenter p-5-0" [ngClass]="isSensorNode ? 'col-4': 'col-2'">
                        <mat-checkbox [disabled]="checkMasterDisable(custompoint)" [(ngModel)]="custompoint.master"
                        [checked]="custompoint.master" [ngModelOptions]="{standalone: true}"></mat-checkbox>   
            </div>
            <div class="col-2 textCenter  p-5-0" *ngIf="!isSensorNode">
                <mat-checkbox [(ngModel)]="custompoint.hover" [checked]="custompoint.hover"
                    [ngModelOptions]="{standalone: true}"></mat-checkbox>
            </div>
            <div class="col-2 textCenter  p-5-0" *ngIf="!isSensorNode">
                <mat-checkbox  [(ngModel)]="custompoint.click" [checked]="custompoint.click"
                    [ngModelOptions]="{standalone: true}"></mat-checkbox>
            </div>
        </div>
        <div class="row p-t-10" *ngIf="!equipDeleted && !isSensorNode && !checkEquipConfigIsSensorOrBuildingOrHeatExchanger">
            <div class="col-6 pointsHeaderText">
                Custom Alerts
            </div>
            <div class="col-6">
                <i (click)="addAlertModel()" class="fa-solid fa-plus addIcon"></i>
            </div>
        </div>
    
        <div *ngFor="let alert of alertList" class="row">
            <div class="col-6 pointText">
                {{alert.alertName}}
            </div>
            <div class="col-6">
                <i (click)="deleteAlertClicked(alert)" class="fa-solid fa-trash deleteIcon"></i>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="equipDeleted">
        <div class="equipDeletedClass">
            The Equipment has been deleted.
        </div>
    </ng-container>
    </div>

    <div class="flex-footer  d-flex justify-content-end p-t-18">
        <div class="col-4" *ngIf="!equipDeleted">
            <button (click)="deleteEquip()" class="text-uppercase btn color-primary text-uppercase">DELETE EQUIP</button>
        </div>
        <div class="col-8 d-flex justify-content-end align-items-center">
            <button (click)="cancel()" class="text-uppercase btn color-primary text-uppercase">CANCEL</button>
            <span class="separatorIcon">|</span>
            <button *ngIf="!equipDeleted" (click)="saveConfig()" class="text-uppercase btn color-primary text-uppercase" [ngClass]="saveButtonDisable ? 'disable': ''" [disabled]="saveButtonDisable" >SAVE CONFIG</button>
            <button *ngIf="equipDeleted" (click)="deleteEquip()" class="text-uppercase btn color-primary text-uppercase" [ngClass]="saveButtonDisable ? 'disable': ''" [disabled]="saveButtonDisable" >REMOVE EQUIP FROM CANVAS</button>
        </div>
    </div>
</div> 