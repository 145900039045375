<div class="addPointModel">
    <div class="titleText">Add Point</div>
    <div class="flex-body relative" #pamBuilderContent>
        <div class="row spaceEvenlyflex">
            <div class="col-3 graphConfigCol p-l-0">
                <div class="labelText">
                    Select Build Type
                </div>
                <div class="btn-group d-flex m-t-10">
                    <button (click)="changeBuildType()" class="btn w-100"
                        [ngClass]="{'btn-outline-primary color-primary':!isCustom}">
                        BUILDER
                    </button>
                    <button (click)="changeBuildType()" class="btn w-100"
                        [ngClass]="{'btn-outline-primary color-primary': isCustom}">
                        CUSTOM
                    </button>
                </div>
                <div *ngIf="showBuildChangeWarning" class="row align-items-start m-t-30 m-b-10 warningtext">
                    <div class="col-2 text-center">
                        <i class="fa fa-exclamation-triangle color-primary fa-lg m-t-10"></i>
                    </div>
                    <div class="col-10 p-l-0 pre-wrap">
                        <p class="warningtext">Are you sure you want to change widget type to <b>{{buildTypechange}}
                                ?</b>
                        </p>
                        <p>Changes you made so far will be lost</p>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-text" (click)="showBuildChangeWarning = false;"> Cancel</button>
                            <button class="btn btn-text" (click)="buildWarningSelected()">
                                Change</button>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'visible':showBuildChangeWarning}">
                    <div class="labelText m-t-16">
                        Scope
                    </div>


                    <site-selector *ngIf="isCustom" [scope]=sites defaultSelection='all'
                        placeholder="Select Scope" class="selectorFormField siteSelector editor notranslate"
                        (scopeSelectionChangeEvent)="scopeChanged($event)" [disableTranslation]="true">
                    </site-selector>

                    <site-selector *ngIf="!isCustom" [scope]=sites defaultSelection='all'
                        placeholder="Select Scope" class="selectorFormField siteSelector editor notranslate"
                        (scopeSelectionChangeEvent)="scopeChanged($event)" [disableTranslation]="true">
                    </site-selector>
                </div>

                <div *ngIf="!isCustom">
                    <div class="labelText m-t-16">
                        Tags
                    </div>
                    <ng-select class="custom-select notranslate" [items]="tagList" color="primary" [multiple]="true" #tagSelect
                        (add)="onTagSelect($event)" (remove)="onTagDeSelect($event)" (clear)="onTagsClear()"
                        [searchFn]="searchTag" addTagText="" [clearOnBackspace]="true" [markFirst]="markFirst()"
                        dropdownPosition="top" [clearSearchOnAdd]="false" [(ngModel)]="selectedTags"></ng-select>
                </div>
            </div>
            <div [ngClass]="{'visible':(selectedSites.length == 0),'graphConfigCol':((!isCustom && equipList.length > 0) || isCustom) }"
                class="col-4 p-l-0">

                <div *ngIf="equipList?.length > 0 && !isCustom">
                    <div *ngIf="!isCustom && selectedSites.length > 0" class="eqipText d-flex">Equip <span
                            class="eqipNumber p-l-3"> ({{selectedEquipsId.length}}/{{equipList.length}})</span> <span
                            class="switch d-flex p-l-8">
                            <mat-slide-toggle [(ngModel)]="isQueryMode">
                            </mat-slide-toggle>
                            <span class="queryLabel" (click)=queryView()>Query </span>
                        </span></div>

                </div>
                <!-- Query  -->
                <textarea *ngIf="isQueryMode || isCustom" #queryElem class="query-textarea"
                    [ngClass]="{'readonlyTextbox': !isCustom}" [readonly]="!isCustom" autofocus spellcheck="false"
                    [(ngModel)]="hayStackQuery" placeholder="Enter your query here"
                    [ngModelOptions]="{standalone: true}">
                    </textarea>

                <div *ngIf="!isQueryMode && !isCustom && equipList?.length > 0 ">
                    <div class="pointSearchInput">
                        <mat-form-field class="search-input" [floatLabel]="'never'">
                            <input matInput placeholder="Search Equip" [(ngModel)]="equipSearch" autocomplete="off" />
                        </mat-form-field>
                    </div>

                    <div class="node-content">
                        <ul class="list-none">
                            <li class="node notranslate" [ngClass]="{'selected':(equipdata.checked)}"
                                (click)="onEquipSelect(equipdata)"
                                *ngFor="let equipdata of equipList | filter:equipSearch:'dis';trackBy: trackByPoints">
                                <span>{{equipdata?.pointName}}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="text-center p-t-12">
                    <div *ngIf="isCustom" (click)="getPoints()"
                        [ngClass]="{'executeQueryDisabled': !selectedSites?.length || !hayStackQuery?.length}"
                        class="retriveText text-uppercase">
                        <span *ngIf="!fetchingPoints">Execute Query</span>
                        <span *ngIf="fetchingPoints">Executing ...</span>
                    </div>

                    <div *ngIf="!isCustom && equipList?.length > 0 && !isCustom" (click)="getPoints()"
                        [ngClass]="{'executeQueryDisabled':!selectedSites?.length || (!isCustom && !selectedTags?.length)}"
                        class="retriveText text-uppercase">
                        <span *ngIf="!fetchingPoints">Retrieve points</span>
                        <span *ngIf="fetchingPoints">Retrieving ...</span>
                    </div>


                </div>


                <div *ngIf="equipCalled && !isCustom &&  equipList?.length === 0 && selectedSites?.length > 0"
                    class="p-t-20">
                    <div class="h-100 warningNoPoint d-flex justify-content-center">
                        <i class="fas fa-exclamation-triangle warningIcon"></i>
                        <div class="m-l-10">
                            <div>There are no points available for the current selection !</div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-4 p-l-0">
                <div *ngIf="selectedSites.length > 0 &&  pointList.length > 0">
                    <div class="eqipText">Points <span
                            class="eqipNumber">({{selectedPoint.length}}/{{pointList.length}})</span>
                    </div>

                    <div class="pointSearchInput">
                        <mat-form-field class="search-input" [floatLabel]="'never'">
                            <input matInput placeholder="Search Point" [(ngModel)]="pointSearch" autocomplete="off" />
                        </mat-form-field>
                    </div>

                    <div class="node-content">
                        <ul class="list-none">
                            <li class="node notranslate" [ngClass]="{'selected':(pointData.checked)}"
                                (click)="onPointSelect(pointData)"
                                *ngFor="let pointData of pointList | filter:pointSearch:'dis';trackBy: trackByPoints">
                                <span>{{pointData?.pointName}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="pointsCalled && pointList?.length === 0 && selectedSites?.length > 0" class="p-t-20">
                    <div class="h-100 warningNoPoint d-flex justify-content-center">
                        <i class="fas fa-exclamation-triangle warningIcon"></i>
                        <div class="m-l-10">
                            <div>There are no points available for the current selection !</div>
                        </div>
                    </div>
                </div>

                <div *ngIf="hasError" class="p-t-20">
                    <div class="h-100 warningNoPoint d-flex justify-content-center">
                        <i class="fas fa-exclamation-triangle warningIcon"></i>
                        <div class="m-l-10">
                            <div>{{errorTxt}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-footer  d-flex justify-content-end p-r-30">
        <div class="col-9 d-flex justify-content-end align-items-center">
            <button (click)="cancel()"
                class="text-uppercase btn btn-outline-primary color-primary text-uppercase">Cancel</button>

            <button (click)="addPoint()" class="text-uppercase btn btn-outline-primary color-primary text-uppercase"
                [ngClass]="{'disabled' : !isPointSelected}" [disabled]="!isPointSelected">Add Point</button>

        </div>
    </div>

</div>